<template>
  <div class="step1">
    <stepper :value="1" />
    <v-row class="name mb-n12 ">
      <v-col lg="6">
        <v-text-field
          @input="setEditItems({ name: $event })"
          class="v-text-field"
          v-model="data.name"
          :label="$t('exercise_name')"
          append-inner-icon="mdi-map-marker"
          outlined
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row class="name mb-n12">
      <v-col lg="6">
        <v-text-field
          @input="setEditItems({ serial: $event })"
          class="text "
          type="text"
          v-model="data.serial"
          :label="$t('id')"
          append-inner-icon="mdi-map-marker"
          outlined
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row class="name mb-n12">
      <v-col lg="6">
        <v-textarea
          @input="setEditItems({ description: $event })"
          v-model="data.description"
          outlined
          :label="$t('description')"
          rows="4"
          row-height="15"
          shaped
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="name mb-n12">
      <v-col lg="6">
        <v-autocomplete
          @input="setEditItems({ age_group_id: $event })"
          v-model="data.age_group_id"
          :label="$t('age group')"
          outlined
          validate-on-blur
          deletable-chips
          item-text="name"
          item-value="id"
          chips
          :menu-props="{ closeOnClick: true }"
          :items="ageItems"
        ></v-autocomplete
      ></v-col>
    </v-row>
    <v-row class="name mb-n12">
      <v-col lg="6">
        <v-autocomplete
          @input="setEditItems({ skills: $event })"
          validate-on-blur
          deletable-chips
          item-text="name"
          item-value="id"
          v-model="data.skills"
          chips
          :label="$t('skilles')"
          :items="skillsItems"
          multiple
          outlined
          :menu-props="{ closeOnClick: true }"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="justify-center mb-4">
      <v-col lg="2"
        ><span>{{ $t("exercise_type") }}</span></v-col
      >
      <v-col cols="12" sm="7" md="6" lg="4">
        <v-sheet elevation="10" rounded="xl">
          <div class="pa-4">
            <v-chip-group
              v-model="data.type"
              active-class="primary--text"
              column
            >
              <v-chip
                v-for="(exercise, index) in types"
                :key="index"
                :value="exercise.value"
                @click="setEditItems({ type: exercise.value })"
              >
                {{ $t(exercise.text) }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <btns
      :loader="loader"
      :disable="validate"
      :action="nextStep"
      :back="previousStep"
    />
  </div>
</template>
<script>
export default {
  props: ["types", "skillsItems", "ageItems", "data", "loader"],
  data() {
    return {
      oddVariant: false,
      editData: {},
      serialCopy: null,
    };
  },
  methods: {
    nextStep() {
      if (this.editData.serial) {
        if (this.editData.serial === this.serialCopy) {
          delete this.editData.serial;
        }
      }
      if (!this.editData.type) {
        Object.assign(this.editData, { type: this.data.type });
      }
      this.$emit("getData", this.editData);
    },
    previousStep() {
      this.$router.go(-1);
    },
    setEditItems(item) {
      Object.assign(this.editData, item);
    },
  },
  computed: {
    validate() {
      return !Object.keys(this.editData).length == 0;
    },
  },
  mounted() {
    this.serialCopy = this.data.serial;
  },
};
</script>
<style lang="scss" scoped>
.step1 {
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
