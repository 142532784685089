<template>
  <div ref="scrollContainer" class="add-exercise">
    <step1
      v-bind:style="{ display: step == 1 ? 'block' : 'none' }"
      @getData="setStep1Data($event)"
      @goBack="$router.go(-1)"
      :types="typesItems"
      :skillsItems="skillItems"
      :ageItems="ageGroupItems"
      :data="dataFill"
      :loader="step1Loader"
    />
    <step2
      v-bind:style="{ display: step == 2 ? 'block' : 'none' }"
      @getData="setStep2Data($event)"
      @goBack="step = 1"
      :schema="dataFill"
      :type="data.type"
      :oldType="orginalDataFill.type"
    />
    <step3
      v-if="step == 3"
      @getData="postData($event)"
      @goBack="step = 2"
      :loader="step3Loader"
      :dataFill="dataFill"
    />
  </div>
</template>
<script>
import { Exercise } from "@/store/exercise/add";
import { Skilles } from "@/store/exercise/skilles";
import { ageGroup } from "@/store/exercise/age-group";
import step1 from "./step1.vue";
import step2 from "./step2.vue";
import step3 from "./step3.vue";
export default {
  components: {
    step1,
    step2,
    step3,
  },
  data() {
    return {
      Exercise,
      Skilles,
      ageGroup,
      step: 1,
      canGoBack: false,
      data: {},
    };
  },
  methods: {
    setStep1Data(data) {
      this.data = data;
      this.step = 2;
    },
    setStep2Data(data) {
      if (data) Object.assign(this.data, { schema: data });
      this.step = 3;
    },
    postData(data) {
      Object.assign(this.data, { background_sound: data.background_sound });
      Object.assign(this.data, { max_grade: data.max_grade });
      Object.assign(this.data, { grade_to_pass: data.grade_to_pass });
      Exercise.put(this.data, this.id);
    },
  },
  computed: {
    skillItems() {
      return Skilles.tableData.data;
    },
    ageGroupItems() {
      return ageGroup.tableData.data;
    },
    typesItems() {
      return Exercise.types;
    },
    id() {
      return this.$route.params.id;
    },
    dataFill() {
      return { ...Exercise.exercise };
    },
    orginalDataFill() {
      return Exercise.exercise;
    },
    step1Loader() {
      return Exercise.showState.loading;
    },
    step3Loader() {
      return Exercise.editState.loading;
    },
  },

  mounted() {
    Skilles.get({ page: 1 });
    ageGroup.get({ page: 1 });
    Exercise.show(this.id);
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/md/variables";
.add-exercise {
  display: block;
  overflow-x: hidden;
  scroll-behavior: smooth;

  .name {
    margin: auto;
    margin-top: -30px;
    justify-content: center;
    .text-field {
      background-color: #90c143 !important;
    }
  }
}
.add-exercise::-webkit-scrollbar {
  width: 0;
}
.add-btn {
  align-self: flex-end;
  .next {
    background-color: $myBlue !important;
    color: white !important;
    width: 10%;
    height: 50px !important;
  }
  .back {
    background-color: white !important;
    color: $myBlue !important;
    width: 10%;
    height: 50px !important;
  }
}
</style>
